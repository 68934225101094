@font-face {
  font-family: "Avenir-Book";
  src: local("AvenirBook"),
    url("./assets/fonts/Avenir-Book.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Avenir-Medium";
  src: local("AvenirMedium"),
    url("./assets/fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Avenir-Heavy";
  src: local("AvenirHeavy"),
    url("./assets/fonts/Avenir-Heavy.ttf") format("truetype");
  font-weight: normal;
}

body {
  height: 100%;
  width: 100%;
  background-color: #f4f6f8;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
